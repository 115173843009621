body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  padding: 1rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.VinButtons {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  max-width: 400px;
  width: 100%;
}

button {
  width: 0;
  height: 50px;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  background-color: green;
  cursor: pointer;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 24px;
  outline: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  -webkit-transition: box-shadow 300ms ease;
  transition: box-shadow 300ms ease;
}

button:hover {
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
    0 5px 15px 0 rgba(0, 0, 0, 0.08);
}

button:active {
  background-color: darkgreen;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: none;
}

button + button {
  margin-left: 0.5rem;
}

@media (max-width: 450px) {
  .VinButtons {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
  }

  button {
    width: 85%;
    margin-left: 0 !important;
  }

  button + button {
    margin-top: 0.5rem;
  }
}

.BarcodeDisplay {
  margin-top: 2rem;
  height: 145px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 90%;
}

.CopyButton {
  background-color: #555;
  font-size: 16px;
  width: 10em;
  padding: 0;
  margin-top: 2rem;
}

.CopyButton:active {
  background-color: #444;
}

footer {
  text-align: center;
  position: absolute;
  bottom: 1rem;
  left: 2rem;
  right: 2rem;
}

/* Copied from a codepen: https://codepen.io/claviska/pen/MoLWbj */
.spinner {
  /* Spinner size and color */
  width: 1.5rem;
  height: 1.5rem;
  border-top-color: #444;
  border-left-color: #444;

  /* Additional spinner styles */
  -webkit-animation: spinner 400ms linear infinite;
          animation: spinner 400ms linear infinite;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-style: solid;
  border-width: 2px;
  border-radius: 50%;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
}
/* Animation styles */
@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

